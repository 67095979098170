<template>
  <div class="ag-status-name-value">
    <span v-if="count">
      <span>Registros filtrados:&nbsp;</span>
      <span class="ag-status-name-value-value">{{ count }}</span>
    </span>
  </div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  data() {
    return {
      count: null,
    };
  },
  mounted() {
    this.params.api.addEventListener('modelUpdated', this.onModelUpdated.bind(this));
  },
  methods: {
    onModelUpdated(params) {
      this.count = this.$formatNumber(params.api?.totalRows) || 0;
    },
  },
});
</script>
